@media screen and (min-width:1199px){
    .about-us-content{ max-width: 787px; padding-left: 55px;}
}
@media screen and (min-width:1480px){
    .container{ width: 1464px;}
    .about-us-content {padding-left: 115px;padding-right: 60px;}
}

@media screen and (max-width:1600px){
    .service-item{ padding: 170px 20px 70px;}
    .service-content p{ font-size: 18px;}
    .service-content h3{ font-size: 26px;}
}
@media screen and (max-width:1440px){
    .header-wrapper{ padding-left: 40px; padding-right: 40px;}
    .main-navigation{ margin: 0 15px;}
    .menu > li{ margin: 0 13px;}
}

@media screen and (max-width:1366px){
    .header-wrapper{ padding-left: 15px; padding-right: 15px;}
    .menu > li{ margin: 0 10px;}

    .service-item{ padding: 150px 35px 80px;}
    .service-item .button{ white-space: nowrap;}
    .service-item:before {
        background: rgba(3, 29, 64, 0.27);
        background: -moz-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 40%, rgba(3, 29, 64, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(3, 29, 64, 0.27)), color-stop(40%, rgba(3, 29, 64, 1)), color-stop(100%, rgba(3, 29, 64, 1)));
        background: -webkit-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 40%, rgba(3, 29, 64, 1) 100%);
        background: -o-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 40%, rgba(3, 29, 64, 1) 100%);
        background: -ms-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 40%, rgba(3, 29, 64, 1) 100%);
        background: linear-gradient(to bottom, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 40%, rgba(3, 29, 64, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#031d40', endColorstr='#031d40', GradientType=0);
    }
}

@media screen and (max-width:1280px){
    .site-header{ top: 15px;}
    .service-content p{ font-size: 16px; margin-bottom: 0;}
    .service-item {padding: 150px 15px 40px;}
    .logo img{ max-width: 255px; margin-top: 8px;}

    .footer-contact a, .footer-contact li{ font-size: 20px;}
    .service-item:before {
        background: rgba(3,29,64,0.27);
background: -moz-linear-gradient(top, rgba(3,29,64,0.27) 0%, rgba(3,29,64,1) 45%, rgba(3,29,64,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(3,29,64,0.27)), color-stop(45%, rgba(3,29,64,1)), color-stop(100%, rgba(3,29,64,1)));
background: -webkit-linear-gradient(top, rgba(3,29,64,0.27) 0%, rgba(3,29,64,1) 45%, rgba(3,29,64,1) 100%);
background: -o-linear-gradient(top, rgba(3,29,64,0.27) 0%, rgba(3,29,64,1) 45%, rgba(3,29,64,1) 100%);
background: -ms-linear-gradient(top, rgba(3,29,64,0.27) 0%, rgba(3,29,64,1) 45%, rgba(3,29,64,1) 100%);
background: linear-gradient(to bottom, rgba(3,29,64,0.27) 0%, rgba(3,29,64,1) 45%, rgba(3,29,64,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#031d40', endColorstr='#031d40', GradientType=0 );
    }
}

@media screen and (max-width:1200px){
    .logo img{ max-width: 265px;}
    .about-us-content {max-width: 787px;padding-left: 15px;}
}

@media screen and (max-width:1199px){
    .menu a{ font-size: 15px;}
    .header-contact-info a{ font-size: 14px;}
    .menu > li{ padding-bottom: 25px; padding-top: 10px; margin: 0 7px;}
    .header-contact-info li{ line-height: 20px;}
    .logo img{ max-width: 200px;}
    .footer-contact a, .footer-contact li{ font-size: 18px;}
}

@media screen and (max-width:1024px){
    h1, .h1{ font-size: 38px; }
    h2, .h2, .inner-page-content h2{ font-size: 24px;}
    blockquote{ font-size: 20px;}
    .testimonial-section{ padding: 45px 0;}

    .main-navigation{ margin: 0 10px;}
    .menu > li{ margin: 0 5px;}
    .footer-contact{ margin-top: -34px;}

    .liscence-number-block p{ font-size: 14px;}
    .service-content h3 {font-size: 22px;line-height: normal;}
    .service-content p{ font-size: 14px;}
}

@media screen and (max-width:991px){
    body{ font-size: 16px; line-height: 22px;}
    h1, .h1{ font-size: 26px; line-height: 37px;}
    h2, .h2, .inner-page-content h2{ font-size: 18px; line-height: 21px;}
    blockquote{ font-size: 18px; line-height: 20px;}

    .header-wrapper{ padding: 10px 10px 0;}
    .header-contact-info a{ font-size: 0;}
    .header-contact-info a .fas{ font-size: 20px; margin-right: 0; margin-bottom: 7px;}
    .logo{ margin-top: 8px;}
    .logo img{ max-width: 170px; margin-top: 0;}
    .header-contact-info{ margin-top: -2px;}
    .header-contact-info a {display: block;line-height: normal;}

    .banner-content{ max-width: 600px;}
    .banner-section{ height: 300px;}

    .service-section .col-sm-3:last-child .service-item{ margin-bottom: 0;}
    .service-item{ padding: 116px 20px 38px; margin-bottom: 47px;}
    .logos-wrapper .logo-item{ margin: 0 20px;}
    .service-item:before{
        background: rgba(3, 29, 64, 0.27);
    background: -moz-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(3, 29, 64, 0.27)), color-stop(60%, rgba(3, 29, 64, 1)), color-stop(100%, rgba(3, 29, 64, 1)));
    background: -webkit-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);
    background: -o-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);
    background: -ms-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);
    background: linear-gradient(to bottom, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#031d40', endColorstr='#031d40', GradientType=0);
    }

    .footer-contact{ padding: 15px 10px; margin-top: -28px;}
    .footer-contact li{ margin: 0 10px;}
    .footer-contact a, .footer-contact li{ font-size: 14px;}

    .inner-page-title{ bottom: 10px;}
    .inner-page-content{ margin-top: 30px;}
    .inner-page-content h2{ margin-bottom: 55px;}

    .page-content-wrapper aside{ margin-top: -55px;}
    .liscence-number-block p{ font-size: 14px; line-height: normal;}
}

@media screen and (max-width:767px){

    .button{ padding: 8px 51px 8px 52px; font-size: 18px;}
    .no-padding-xs{ padding: 0;}
    .no-margin-xs{ margin: 0;}

    .menu a:hover, .menu > li.current-menu-item > a, .menu > li.current_page_item > a, .sub-menu > li.current-menu-item > a, .sub-menu > li.current_page_item > a, .menu > li.current-page-ancestor > a, .menu > li.current-menu-ancestor > a, .menu > li.current-menu-parent > a, .menu > li.current-page-parent > a, .menu > li.current_page_parent > a, .menu > li.current_page_ancestor > a{ color: #fff;}

    .site-header{ top: 0; position: static;}
    .site-header .container-fluid{ padding: 0;}
    .header-wrapper{ border-radius: 0; padding: 10px 0 0;}
    .logo{ margin-top: 0; width: 100%; float: none; margin-bottom: 10px;}
    .logo img{ max-width: 281px;}
    .sub-menu{ opacity: 1;visibility: visible; position: static;width: auto; background: none;}
    .menu > li{ float: none; padding: 0; margin: 0;}
    .main-navigation {margin: 0;position: fixed;height: 100%;background-color: #031d40;right: -280px;top: 0; text-align: left; padding: 20px; width: 280px; overflow-x: hidden; overflow-y: auto;  -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
    .header-contact-info{ float: none;background-color: #031d40; display: -webkit-box; display: -ms-flexbox; display: flex; display: -webkit-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-justify-content: space-between; padding: 13px 25px;}
    .header-contact-info a {color: #FF5000;;}
    .header-contact-info a .fas{ margin-bottom: 0;}
    .header-contact-info li{ float: left;}
    .contact-phone{ margin-right: 15px;}
    .contact-mail{ margin-left: 15px;}

    /* TOGGLE MENU */
    .site{ position: relative; right: 0; -webkit-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
    .menu-open .site{ right: 280px;}

    .menu-open .main-navigation{ right: 0;}
    .menu a:hover{ color: #fff;}
    .menu > li{ margin: 5px 0;}
    .sub-menu > li{ margin: 10px 0;}
    .sub-menu > li:first-child{ margin-top: 10px;}
    .sub-menu > li:last-child{ margin-bottom: 10px;}

    .menu-toggle .fa-times {display: none;}
    .menu-open .menu-toggle .fa-bars{ display: none;}
    .menu-open .menu-toggle .fa-times {display: block;}

    .banner-slider-section{ height: 425px;}
    .banner-content{ max-width: 100%; border-radius: 0; padding-left: 20px; padding-right: 20px; padding-bottom: 30px; width: 100%; bottom: 98px; left: 0; -webkit-transform: none; transform: none;}
    .banner-content h2{ font-size: 26px; line-height: 30px;}
    .banner-content h1{ font-size: 35px; line-height: 37px;}
    .banner-content p{ font-size: 16px; line-height: 22px;}

    .logos-wrapper .logo-item{ margin: 0 6px;}

    .service-section { padding: 45px 0 0;}
    .service-item{ border-radius: 0;}
    .service-item .button{ bottom: -23px; z-index: 1;}
    .service-item:before { border-radius: 0;}

    .testimonial-section{ background-color: #fff;}

    .footer-about-us{ padding-top: 65px;}
    .about-us-content{ margin-bottom: 46px;margin-top: 0;}
    .about-us-content p{ line-height: 28px;}

    .footer-contact{ border-radius: 0; padding: 19px 26px; margin-top: 0;}
    .footer-about-us:before { display: none;}
    .footer-contact ul{ flex-direction: column; align-items: flex-start;}
    .footer-contact li{ display: -webkit-box; display: -ms-flexbox; display: flex; display: -webkit-flex; margin: 10px 0;}
    .footer-contact li, .footer-contact a{ font-size: 16px; line-height: 17px;}
    .footer-contact li i{ min-width: 20px; margin-right: 10px; text-align: center}

    .copyright-socket{ text-align: center;padding: 25px 0 75px;}
    .copyright-socket .col-sm-6.text-right {text-align: center;}
    .copyright-socket .button{ margin-top: 37px;}
    p.site-by {margin-top: 15px;display: block;}

    .banner-section{ height: auto;}
    .banner-section:after{ background-image: none; background-color: rgba(0,0,0,0.5);}
    .inner-page-title { bottom: 50%; -webkit-transform: translateY(50%); transform: translateY(50%); padding: 0 20px;}
    .inner-page-title h1{ text-align: center;}
    .inner-page-content h2{ text-align: center;}
    .page-content-wrapper aside{ margin-top: 20px; width: 100%; display: inline-block;}
    .logo-section{ padding-bottom: 30px;}
    .liscence-number-block p{ font-size: 14px;}
    .liscence-number-block p:last-child{ margin-bottom: 0;}

    .testimonial-content .button{ line-height: normal; font-size: 16px;}

}
@media screen and (max-width:320px){
    .header-contact-info{ padding: 13px 10px;}
    .inner-page-title{ padding: 0;}
    .footer-contact li i{ margin-right: 6px;}
}

//Fix service section to center for 3 or 4 items
@media screen and (min-width:991px) {
.service-section .row.no-margin-xs{ display: table-row; }
.service-section .col-md-3.no-padding-xs{ display: table-cell; float: none; vertical-align: top;}
}
