/*--------------------------------------------------------------
# FONTS USED
--------------------------------------------------------------
font-family: adelle;
font-family: aktiv-grotesk;
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# GLOBAL
--------------------------------------------------------------*/
body{ font-size: 20px; color: #000; letter-spacing: 0; line-height: normal; font-family: aktiv-grotesk; font-weight: 400;}
section{ position: relative;}
img{ max-width: 100%; height: auto;}
a, a:hover, a:focus, a:active{ text-decoration: none; outline: none;}
.button{ font-size: 21px; line-height: 29px; color: #fff; background: rgba(7,62,116,1);background: -moz-linear-gradient(top, rgba(7,62,116,1) 0%, rgba(3,29,64,1) 100%);background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(7,62,116,1)), color-stop(100%, rgba(3,29,64,1)));background: -webkit-linear-gradient(top, rgba(7,62,116,1) 0%, rgba(3,29,64,1) 100%);background: -o-linear-gradient(top, rgba(7,62,116,1) 0%, rgba(3,29,64,1) 100%);background: -ms-linear-gradient(top, rgba(7,62,116,1) 0%, rgba(3,29,64,1) 100%);background: linear-gradient(to bottom, rgba(7,62,116,1) 0%, rgba(3,29,64,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#073e74', endColorstr='#031d40', GradientType=0 ); border-radius: 100px; padding: 16px 51px 12px 52px; display: inline-block; -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); -webkit-transition: 0.3s ease all 0s; transition: 0.3s ease all 0s; min-width: 205px; text-align: center;}
.button:hover, .button:focus{ color: #FF8200;}
.button.orange-button{background: rgba(255,136,0,1);background: -moz-linear-gradient(top, rgba(255,136,0,1) 0%, rgba(255,81,0,1) 100%);background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,136,0,1)), color-stop(100%, rgba(255,81,0,1)));background: -webkit-linear-gradient(top, rgba(255,136,0,1) 0%, rgba(255,81,0,1) 100%);background: -o-linear-gradient(top, rgba(255,136,0,1) 0%, rgba(255,81,0,1) 100%);background: -ms-linear-gradient(top, rgba(255,136,0,1) 0%, rgba(255,81,0,1) 100%);background: linear-gradient(to bottom, rgba(255,136,0,1) 0%, rgba(255,81,0,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8800', endColorstr='#ff5100', GradientType=0 );}
.button.orange-button:hover, .button.orange-button:focus{ color: #031D40;}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{ font-family: adelle; font-weight: 700;}


h1, .h1{ font-size: 46px; line-height: 44px;}
h2, .h2{ font-size: 32px; line-height: 44px;}
h3, .h3{ font-size: 30px; line-height: 35px;}
h4, .h4{}
h5, .h5{}
h6, .h6{}

/*--------------------------------------------------------------
# HEADER
--------------------------------------------------------------*/

.site-header {position: absolute;width: 100%;left: 0px;top: 45px;height: auto;z-index: 2; text-align: center;}
.header-wrapper {max-width: 1513px;padding: 15px 64px 0;margin: 0 auto;background-color: #fff;border-radius: 15px;-webkit-box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);}
.logo{ float: left;}
.logo a{ display: block;}
.main-navigation {display: inline-block;vertical-align: middle;}
.header-contact-info{ float: right; text-align: left;}
.site-header ul, .site-footer ul { padding-left: 0; margin-bottom: 0;}
.site-header li, .site-footer li{ list-style: none;}

.logo img{ max-width: 337px;}

.menu a{ font-size: 17px; color: #FF5000; font-style: italic; font-weight: 400; font-family: adelle; text-transform: lowercase; -webkit-transition: all 0.3s ease; transition: all 0.3s ease;}
.menu a:hover, .menu > li.current-menu-item > a, .menu > li.current_page_item > a, .sub-menu > li.current-menu-item > a, .sub-menu > li.current_page_item > a, .menu > li.current-page-ancestor > a, .menu > li.current-menu-ancestor > a, .menu > li.current-menu-parent > a, .menu > li.current-page-parent > a, .menu > li.current_page_parent > a, .menu > li.current_page_ancestor > a{ color: #031d40;}
.menu > li{ float: left; margin: 0 16px; padding: 16px 0 35px;}

.menu > li.menu-item-has-children{ position: relative}
.menu > li:first-child{ margin-left: 0;}
.menu > li:last-child{ margin-right: 0;}
.sub-menu {position: absolute;background-color: #fff;width: 300px;padding: 0 15px !important;top: 100%;left: -15px; text-align: left; opacity: 0; visibility: hidden; -webkit-transition: all ease 0.3s; transition: all ease 0.3s;}
.menu > li.menu-item-has-children:hover .sub-menu, .menu > li.menu-item-has-children.hover .sub-menu{ opacity: 1;visibility: visible;}
.sub-menu a{ display: block;}
.sub-menu > li {margin: 7px 0;}
.sub-menu > li:first-child{ margin-top: 15px;}
.sub-menu > li:last-child{ margin-bottom: 15px;}

.header-contact-info a {font-size: 16px;line-height: 17px;color: #031d40; -webkit-transition: all 0.3s ease 0s; transition: all 0.3s ease 0s;}
.header-contact-info a:hover {color: #FF5000;}
.header-contact-info a .fas {margin-right: 15px;}

/*--------------------------------------------------------------
# SLIDER
--------------------------------------------------------------*/

.banner-slider-section{ height: 660px;}
.banner-slider .item{ height: 100%; background-size: cover; background-repeat: no-repeat; background-position: center center;}
.banner-slider .item img{ opacity: 0; visibility: hidden;}
.banner-content {position: absolute;left: 50%;-webkit-transform: translateX(-50%);transform: translateX(-50%); bottom: 165px; text-align: center;background: #FF5000;-webkit-box-shadow: 0 6px 10px 0 rgba(0,0,0,0.71);box-shadow: 0 6px 10px 0 rgba(0,0,0,0.71);border-radius: 15px;max-width: 750px; padding: 0 87px 40px 87px; z-index: 1; width: 100%;}
.banner-content h2 {font-size: 36px;line-height: 43px;color: #031D40; font-style: italic; margin-bottom: 0;}
.banner-content h1 {font-size: 46px;line-height: 54px;color: #fff;font-family: aktiv-grotesk; margin-top: 0;}
.banner-content p{ font-size: 21px; line-height: 29px; color: #fff; margin-bottom: 0;}
.banner-content .button{ position: absolute; bottom: -30px; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%);}

.banner-slider.owl-carousel { height: 100%; }
.owl-carousel .owl-item, .owl-carousel .owl-stage, .owl-carousel .owl-stage-outer{ height: 100%; }

/*--------------------------------------------------------------
# PARTNER LOGO SECTION
--------------------------------------------------------------*/
.logo-section{ padding-top: 31px; padding-bottom: 80px;}
.home .logo-section{ padding-bottom: 0;}
.logos-wrapper {max-width: 1067px;margin: 0 auto;display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-align: center;-ms-flex-align: center;-webkit-align-items: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-justify-content: center;}
.logos-wrapper .logo-item{ margin: 0 40px 0 39px;}
.logos-wrapper .logo-item:first-child{ margin-left: 0}
.logos-wrapper .logo-item:last-child{ margin-right: 0}
.logos-wrapper .logo-item a{ display: block;}


/*--------------------------------------------------------------
# FOOTER ABOUT US
--------------------------------------------------------------*/
.footer-about-us {background-color: #031D40; overflow: hidden;}
.footer-about-us .row{display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-align: center;-ms-flex-align: center;-webkit-align-items: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;-webkit-justify-content: center;justify-content: center;}
.footer-about-us:before {content: "";position: absolute;right: 0;top: -160px;background-color: rgba(0,0,0,0.18);width: 100%;height: 530px;-webkit-transform: skewY(9deg);transform: skewY(9deg);}

.about-us-content{ margin-bottom: 128px; margin-top: 119px; float: right;}
.about-us-content p{ color: #fff; margin-bottom: 20px; line-height: 31px;}
.about-us-content p:last-of-type, .about-us-content p:last-child{ margin-bottom: 0;}
.about-us-content .button{ margin-top: 24px;}


/*--------------------------------------------------------------
# SERVICE SECTION
--------------------------------------------------------------*/
.service-section{ padding: 45px 12px 62px 12px;}
.service-item {background-size: 100% auto;background-position: top center;background-repeat: no-repeat; position: relative; padding: 217px 50px 102px; border-radius: 15px;}
.service-item:before {content: "";position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: rgba(3, 29, 64, 0.27);background: -moz-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(3, 29, 64, 0.27)), color-stop(60%, rgba(3, 29, 64, 1)), color-stop(100%, rgba(3, 29, 64, 1)));background: -webkit-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);background: -o-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);background: -ms-linear-gradient(top, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);background: linear-gradient(to bottom, rgba(3, 29, 64, 0.27) 0%, rgba(3, 29, 64, 1) 60%, rgba(3, 29, 64, 1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#031d40', endColorstr='#031d40', GradientType=0);border-radius: 15px;}
.service-content{ position: relative; z-index: 1;}
.service-content h3{ color: #fff; text-transform: uppercase; font-style: italic; font-family: adelle; font-size: 32px;}
.service-content p{ color: #fff;}
.service-item .button{ position: absolute; left: 50%; bottom: -30px; -webkit-transform: translateX(-50%); transform: translateX(-50%); white-space: nowrap;}

/*--------------------------------------------------------------
# TESTIMONIAL SECTION
--------------------------------------------------------------*/
.testimonial-section { padding: 75px 0 79px; margin-bottom: 20px; background-size: cover; background-position: center center; background-repeat: no-repeat;}
.testimonial-section:before{ content: ""; background-color: rgba(237,237,237,0.6); width: 100%; height: 100%; top: 0; left: 0; position: absolute;}
blockquote{ border: none; padding: 0; font-size: 24px; line-height: 28px; font-family: adelle; font-style: italic; color: #031D40;}
.testimonial-content{ text-align: center; max-width: 1090px; margin: 0 auto; position: relative; z-index: 1;}
.testimonial-content .button{ margin-top: 50px;}

.ratings{ background: url(../media/star-sprite.png) no-repeat; height: 40px; display: inline-block; margin-bottom: 40px;}
.ratings.one-star{ background-position: 0 0; width: 40px;}
.ratings.one-half-star{ background-position: 0 -49px; width: 90px;}
.ratings.two-star{ background-position: 0 -99px; width: 90px;}
.ratings.two-half-star{ background-position: 0 -149px; width: 140px;}
.ratings.three-star{ background-position: 0 -199px; width: 140px;}
.ratings.three-half-star{ background-position: 0 -249px; width: 190px;}
.ratings.four-star{ background-position: 0 -299px;  width: 190px;}
.ratings.four-half-star{ background-position: 0 -349px; width: 240px;}
.ratings.five-star{ background-position: 0 -399px; width: 240px;}

/*--------------------------------------------------------------
# FOOTER
--------------------------------------------------------------*/

.site-footer{ background-color: #031D40;}
.footer-contact {max-width: 1513px;margin: 0 auto;background-color: #000;border-radius: 100px;padding: 26px 55px;margin-top: -40px;z-index: 1;position: relative;}
.footer-contact li {color: #FF8200; font-size: 25px; line-height: 29px; margin: 0 15px;}
.footer-contact a{ font-size: 25px; line-height: 29px; color: #FF8200; -webkit-transition: 0.3s ease all 0s; transition: 0.3s ease all 0s;}
.footer-contact a:hover{ color: #fff;}
.footer-contact li i {color: #fff; margin-right: 0;}
.footer-contact ul {display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-align: center;-ms-flex-align: center;-webkit-align-items: center;align-items: center;-ms-flex-pack: distribute;justify-content: space-around;-webkit-justify-content: space-around;width: 100%;}

.copyright-socket {padding: 25px 0;}
.copyright-part p {font-size: 15px;line-height: 18px;margin: 0;color: #fff;}
.copyright-part a {color: inherit;}
.copyright-part a:focus, .copyright-part a:hover {text-decoration: underline;}

/*--------------------------------------------------------------
# INNER PAGE
--------------------------------------------------------------*/
.banner-section {background-size: cover;background-repeat: no-repeat;background-position: center center; min-height: 230px; height: 409px;}
.banner-section > img{ opacity: 0; visibility: hidden;}
.banner-section:after{ content: ""; position: absolute; left: 0; top: 0;width: 100%; height: 100%; background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 65%, rgba(0,0,0,0.69) 89%);}
.inner-page-title {position: absolute;width: 100%;left: 0;bottom: 25px;z-index: 1;}
.inner-page-title h1 {color: #fff; font-style: italic; margin-top: 0;}

.inner-page-content { margin-top: 57px;}
.inner-page-content h2{ margin-top: 0; font-weight: 400; font-style: italic; font-size: 30px; line-height: 35px; margin-bottom: 85px;}
.inner-page-content p { margin-bottom: 30px;}

.page-content-wrapper aside {margin-top: -75px; -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); position: relative; z-index: 1;}
.liscence-number-block {background: rgba(7,61,116,1);background: -moz-linear-gradient(top, rgba(7,61,116,1) 0%, rgba(3,29,64,1) 100%);background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(7,61,116,1)), color-stop(100%, rgba(3,29,64,1)));background: -webkit-linear-gradient(top, rgba(7,61,116,1) 0%, rgba(3,29,64,1) 100%);background: -o-linear-gradient(top, rgba(7,61,116,1) 0%, rgba(3,29,64,1) 100%);background: -ms-linear-gradient(top, rgba(7,61,116,1) 0%, rgba(3,29,64,1) 100%);background: linear-gradient(to bottom, rgba(7,61,116,1) 0%, rgba(3,29,64,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#073d74', endColorstr='#031d40', GradientType=0 );color: #fff;padding: 30px 35px;text-align: center;}
.liscence-number-block p{ font-size: 16px; line-height: 25px;}

.sidebar-image img{ width: 100%;}

/*----------------------------------------------------------
# Forms
-----------------------------------------------------------*/
.field-wrap input[type=button], .field-wrap input[type=submit] { background: #e64800; border: none; color: #ffffff;  padding: 8px 16px; transition: background .15s; }
.field-wrap input[type=button]:hover, .field-wrap input[type=submit]:hover { background: lighten(#e64800, 5%); }
